<template>
    <section style="height: calc(100vh - 146px);">
        <div class="row mx-0 h-100">
            <div class="menu-prinicipal border bg-white br-10 px-0 col-xl-auto col-lg-auto col-md-12 col-sm-12 col-12 h-100">
                <div class="row mx-0 py-2 mb-2 align-items-center border-bottom">
                    <div class="d-none select-responsive col-5">
                        <el-select
                        v-model="value"
                        placeholder="Seleccionar Pedido"
                        filterable
                        clearable
                        remote
                        :loading="loading"
                        :remote-method="busquedaa"
                        @change="seleccionar_pedido(value)"
                        >
                            <el-option
                            v-for="(item, idx) in pedidos"
                            :key="idx"
                            :label="`ID ${item.id} - ${item.nombre}`"
                            :value="item.id"
                            />
                        </el-select>
                        <p class="text-general f-13 pl-2">
                            <i>*Ultimos 10 pedidos*</i> 
                        </p>
                    </div>
                    <div class="col search-input pl-2 my-auto">
                        <el-input v-model="buscar" size="small" placeholder="Buscar" clearable class="br-20" @input="busqueda" />
                    </div>
                    <el-tooltip class="item" effect="light" content="Filtro Pedidos" placement="bottom">
                        <div class="btn-action border mr-2" @click="openFiltro">
                            <i class="icon-filter-outline f-22" />
                        </div>
                    </el-tooltip>
                </div>
                <div class="col px-2 listar-pedidos" style="width:400px;height:calc(100% - 55px);">
                    <!-- listado pedidos -->
                    <!--
                    <div v-show="pedidos.length" class="row mx-3 my-2 p-2 px-3 br-10 border bg-light-f5 text-general2 justify-center">
                        <span>{{ paginate.total }} pedidos</span>
                    </div>
                    -->
                    <div class="overflow-auto custom-scroll mb-2" style="max-height: calc(100% - 120px);">
                        <div v-for="(data, index) in pedidos" :key="index">
                            <div class="row mx-3 mb-3 align-items-center container-tipo-pedido px-3 br-20 border">
                                <span class="text-general" style="text-transform:capitalize;">{{ data.mes | helper-fecha('MMMM Y') }}</span>
                                <div class="br-20 f-12 bg-general3 f-600 p-1 text-white d-middle-center ml-auto" style="min-width:20px;height:20px;">
                                    {{ data.pedidos.length }}
                                </div>
                            </div>

                            <div v-for="(ped, idx) in data.pedidos" :key="idx" class="row mx-0 d-middle cr-pointer mb-3 mt-2 pb-2" @click="seleccionar_pedido(ped.id)">
                                <div :class="`mx-2 px-1 br-5 ${activo === ped.id ? 'bg-general':''}`" style="height:140px;" />
                                <div class="card-pedido dentro col-11 px-0 tres-puntos f-15 py-2">
                                    <div class="row mx-2 align-items-center">
                                        <div class="col-6 d-middle px-2 text-general">
                                            <div class="text-center mr-2" style="width:18px;">
                                                <i class="f-20" :class="ped.manual === 0 ? iconPedido(ped.estado) : 'icon-storefront text-pink'" />
                                            </div>
                                            {{ identifyState(ped.estado) }}
                                        </div>
                                        <div class="col text-left px-2 f-600">
                                            <p class="text-general">
                                                <i class="icon-product f-15" />
                                                {{ convertMoneyTendero(ped.valor_final,ped.idm_moneda) }}
                                                <span class="mr-1 text-general">
                                                    ({{ ped.cant_productos }})
                                                </span>
                                            </p>
                                        </div>
                                    </div>
                                    <div class="row mx-2">
                                        <div class="col-6 d-flex px-2">
                                            <div style="width:24px;" />
                                            <p class="tres-puntos f-600 text-general">No. {{ ped.id }}</p>
                                        </div>
                                        <div v-if="ped.pedido_directo" class="col px-2 text-left text-general">
                                            <i class="icon-cedis f-14" />
                                            <span class="ml-1">
                                                Gestión CEDIS
                                            </span>
                                        </div>
                                    </div>
                                    <div class="row mx-2 align-items-center mt-2 border-top py-2">
                                        <img :src="ped.foto" class="obj-cover rounded-circle" width="46" height="46" />
                                        <div class="col px-2">
                                            <p class="text-grey f-14">{{ ped.nombre }}</p>
                                            <p class=" text-grey f-14 tres-puntos">{{ ped.fecha_entrega | helper-fecha('DD MMMM h:mm a') }}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!--
                        <div v-for="(ped, idx) in pedidos" :key="idx" class="row mx-0 d-middle cr-pointer my-2 border-bottom pb-2" @click="seleccionar_pedido(ped.id)">
                            <div :class="`mx-2 px-1 br-5 ${activo==ped.id?'bg-general':''}`" style="height:75px;" />
                            <div :class="`col-11 px-0 tres-puntos f-15 br-10 ${activo==ped.id?'bg-whitesmoke':''}`">
                                <div class="row mx-0">
                                    <div class="col-7 px-2 pl-4 d-middle">
                                        <i v-show="[32,33,34].indexOf(ped.estado) > -1" class="icon-attention-alt text-danger f-18" />
                                        <p class="tres-puntos text-general">ID:{{ ped.id }}</p>
                                    </div>
                                    <div class="col-5 px-1 tres-puntos text-right">
                                        <i :class="`f-18 ${icon_estado(ped.estado)} ${activo==ped.id?'text-general-red':'text-general2'}`" />
                                        {{ estado_pedido(ped.estado) }}
                                    </div>
                                </div>
                                <div class="row px-3 mx-0 my-1 justify-content-between">
                                    <div class="col-6 px-2 text-general f-600">
                                        <p class="tres-puntos">{{ convertMoneyTendero(ped.valor_final,ped.idm_moneda) }}</p>
                                    </div>
                                    <div class="col px-2 text-general2">
                                        <p class="tres-puntos f-13">{{ ped.fecha_entrega | helper-fecha('DD MMM hh:mm a') }}</p>
                                    </div>
                                </div>
                                <div class="row px-3 mx-0">
                                    <div class="col-8 px-2 d-middle text-general">
                                        <p class="tres-puntos">{{ ped.direccion }}</p>
                                    </div>
                                    <div class="col-4 px-1 text-general">
                                        <b class="mr-1">
                                            {{ ped.cant_productos }}
                                        </b>
                                        Productos
                                    </div>
                                </div>
                            </div>
                        </div>
                        -->
                    </div>
                    <!--
                    <div class="row mx-0 justify-center">
                        <el-pagination
                        v-show="pedidos.length"
                        small
                        background
                        class="text-center paginar-general"
                        :current-page="paginate.current_page"
                        :page-size="paginate.per_page"
                        layout="prev, pager, next"
                        :total="paginate.total"
                        @current-change="listar_pedidos"
                        />
                    </div>
                    -->
                    <div v-if="pedidos.length === 0" class="row h-100 mx-0 align-items-center justify-content-center">
                        <div class="text-center f-15 f-600 d-middle-center flex-column" style="width:225px;">
                            <div class="d-middle-center bg-whitesmoke br-5" style="width:115px;height:115px;">
                                <img height="110" src="/img/no-imagen/clock.svg" alt="" />
                            </div>
                            <p>Aún no has recibidos, cancelado o rechazado pedidos</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="px-0 border col bg-white br-t-10 menu-prinicipal  h-100 ml-3" style="width: calc(100vw - 560px);">
                <listadoHistorial />
            </div>
        </div>
        <filtro-pedidos ref="modalFiltro" :opcion="5" @filtrar="accion_filtro" />
    </section>
</template>

<script>
import {mapGetters} from 'vuex'
export default {
    metaInfo: () =>({ title: 'Historial'}),
    components: {
        listadoHistorial: () => import('./listadoHistorial'),
    },
    data(){
        return {
            buscar: '',
            activo: -1,
            pagina_actual:1,
            fecha_filtro:[],
            tipo_filtro:0,
            estados_filtro:[],
            value: '',
            loading: false,
            iconPedido(tipo){
                switch (tipo){
                case 110:
                case 11:
                    return 'icon-playlist-edit'
                case 13:
                    return 'icon-account-edit '
                case 12:
                    return 'icon-account-check'
                case 5:
                    return 'icon-storefront'
                case 31:
                    return 'icon-attention-alt'
                default:
                    return ''
                }
            },
        }
    },
    computed:{
        ...mapGetters({
            pedidos: 'pedidos/pedidos_historial/pedidos',
            paginate: 'pedidos/pedidos_historial/paginate',
        }),
        rol(){
            return this.$usuario.rol_nombre
        }
    },
    destroyed(){
        this.$store.dispatch('pedidos/pedidos_historial/set_data')
    },
    mounted(){
        this.listar_pedidos(1)
    },
    methods: {
        busquedaa(t){
            this.buscar = t
            this.busqueda(t)
        },
        busqueda(text){
            console.log(text);
            const change = () => {
                if(text.length > 1){
                    this.listar_pedidos(1)
                }else if(text.length === 0){
                    this.listar_pedidos(1)
                    this.seleccionar_pedido(null)
                }
            }
            this.delay(change)
        },
        async listar_pedidos(page){
            try {
                this.pagina_actual = page
                let params = {
                    page:this.pagina_actual,
                    query:this.buscar,
                    tipo:this.tipo_filtro,
                    fecha:this.fecha_filtro,
                    estados:this.estados_filtro
                }
                await this.$store.dispatch('pedidos/pedidos_historial/listar_pedidos',params)
            } catch (e){
                this.error_catch(e)
            }
        },
        seleccionar_pedido(id){
            this.activo = id
            this.$store.commit('pedidos/pedidos_historial/set_id_pedido', id)
        },
        icon_estado(estado){
            switch (estado){
            case 4:
                return 'icon-shopping'
            case 21:
            case 22:
            case 23:
            case 26:
                return 'icon-cancel-circled-outline'
            case 24:
            case 27:
                return 'icon-account-remove-outline'
            case 25:
                return 'icon-playlist-remove'
            case 32:
            case 33:
            case 34:
                return 'icon-shopping'
            default:
                return ''
            }
        },
        estado_pedido(estado){
            switch (estado){
            case 32:
            case 33:
            case 34:
            case 4:
                return 'Entregado'
            case 21:
            case 22:
            case 24:
            case 26:
            case 23:
            case 27:
                return 'Cancelado'
            case 25:
                return 'Rechazado'
            default:
                return ''
            }
        },
        accion_filtro(obj){

            this.fecha_filtro = obj.fecha
            this.tipo_filtro = obj.tipo
            this.estados_filtro = obj.estados

            this.listar_pedidos(1)
        },
        openFiltro(){
            this.$refs.modalFiltro.open()
        },
        identifyState(state){
            switch (parseInt(state)){
            case 4:
                return 'Finalizado'
            case 25:
                return 'Rechazado'
            case 26:
                return 'Cancelado tienda'
            case 27:
                return 'Cancelado cliente'
            case 32:
                return 'Reclamo finalizado'
            case 33:
                return 'Soporte'
            case 34:
                return 'Soporte finalizado'
            default:
                return '---'
            }
        }
    }
}
</script>

<style lang="scss" scoped>
    .container-tipo-pedido{
        background-color: #DFE4E8;
        color: #000000;
        height: 35px;
    }
    .card-pedido{
        min-height: 130px;
        box-shadow: 0px 2px 4px #00000014;
        border-radius: 10px;
    }
@media(max-width:1000px){
    .menu-prinicipal{
        background: #fff;

        .listar-pedidos{
            display: none !important;
        }
    }
    section .row{
        height:auto !important;
    }
    .select-responsive{
        display: block !important;
    }
    .search-input{
        display: none !important;
    }
}
@media(max-width:1224px){
    .listar-pedidos{
        width: 350px !important;
    }
}
</style>
